#landing {
    #banner {
        margin: 100px auto 130px;
    }
    #name {
        font-weight: bold;
        font-size: 40px;
        display: flex;
        flex-direction: row;
        #hahnbee {
            position: relative;
            #link {
                position: absolute;
                left: 10px; 
                top:0px;
                color: #4a4a4a;
            }
            img {
                margin-left: 4px;
                padding-left: 2px;
                z-index: -1;
                display: block;
                width: 182px;
                margin-top: 21px;
                height: 32px;
            }
        }
        
    }
    #me {
        font-size: 30px;
    }
}

.headshot {
    max-width: 270px;
    max-height: 270px;
}

@media only screen and (max-width: 600px) {
    /* For mobile phones: */
    .parent{
        flex-wrap: wrap-reverse;
    }

    .headshot{
        max-width: 40%;
    }
}