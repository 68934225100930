.article {
  .center {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 20px;
  }
  .centered-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    img {
      margin: 0px 10px 20px;
    }
  }
}
