#react-components {
    #apps {
        display: flex;
        flex-direction: column;
        text-align: center;
        .row{
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            .app {
                padding: 26px;
                display: flex;
                flex-direction: column;
                img {
                    max-height: 350px;
                }
            }
        }
    }      
}